@import './app/styles/colors';

html {
  font-family: "Brandon", "Roboto", Arial;
  line-height: 1;
  background-color: $black;
}

body {
  margin: auto;
  color: $lightGray;

  @media (min-width: 650px) {
    max-width: 98%;
  }
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
  font-weight: normal;
  margin: 1rem 0;
}

h5 {
  font-size: 1.33rem;
  font-weight: normal;
}

p {
  margin: .5em 0;
}

@font-face {
  font-family: 'Brandon';
  font-weight: normal;
  src: url('/assets/fonts/BrandonRegular.woff2') format("woff2");
}

@font-face {
  font-family: 'Brandon';
  font-weight: bold;
  src: url('/assets/fonts/BrandonBold.woff2') format("woff2");
}

@font-face {
  font-family: 'Brandon';
  font-weight: 1000;
  src: url('/assets/fonts/BrandonBlack.woff2') format("woff2");
}

.coversheetRowTemplate {

  .blockTime {
    font-weight: bold;
    font-size: 1.5em;
    padding: 0 16px;

    &>* {
      // border-bottom: thin solid $warmGray;
      text-decoration: underline;
    }
  }
}

@media (max-width: 650px) {
  .header-time-row {
    display: none !important;
  }
  .mat-expansion-panel-content {
    overflow: scroll !important;
  }
}


.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
  background: transparent !important;
}

@media (max-width: 650px) {
  .coversheetRowTemplate {
    .blockTime {
      padding: 0 4px;
    }
  }
}