//brand colors
$gold: #cf9c51;
$warmGray: #776b62;
$lightGray: #e9e6d5;
$black: #000000;
$almostBlack: #111111;
$white: #ffffff;
$textGray: #606060;

$blue: #5451cf;
$green: #54cf51;
$red: #cf5151;
$yellow: #cfc851;
$orange: #cf8c51;
$purple: #9d51cf;